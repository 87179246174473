import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import { VIDEO } from '../../api/videos';
import ReactLogo from '../../assets/reactive_logo.png';
import Button from '../../components/Button';
import Subtitle from '../../components/Subtitle';
import Title from '../../components/Title';
import { client } from '../../config/apolloClient';
import { QuizContext } from '../../context/quizContext';
import withOrientationGuard from '../../hocs/withOrientationGuard';
import Layout from '../../layouts';
import { VideoQuery, VideoQueryVariables } from '../../types/graphql-generated';

function CodePage() {
  const [, setVideo] = useContext(QuizContext);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [code, setCode] = React.useState('');
  const [codeError, setCodeError] = React.useState('');

  const history = useHistory();

  return (
    <Layout withHeader>
      <form className="flex flex-col items-center" onSubmit={handleSubmit}>
        <div>
          <img className="w-40" src={ReactLogo} alt="Reactive Logo" />
        </div>

        <Title>READY TO REACT?</Title>
        <Subtitle>Enter a React Code:</Subtitle>

        <label htmlFor="code" className="sr-only">
          Code
        </label>
        <div className="w-64 mt-3 relative shadow-sm">
          <input
            id="code"
            value={code}
            onChange={event => {
              setCodeError('');
              setCode(event.target.value);
            }}
            className="form-input rounded-none font-poppins block w-full"
            placeholder="Codes are Case Sensitive"
          />
        </div>

        {setCodeError && <p className="mt-2 font-semibold font-poppins text-red-500">{codeError}</p>}

        <Button type="submit" disabled={!code || isSubmitting} title="GO!" />
      </form>
    </Layout>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (code !== 'pizza2018') {
      setCodeError('React Code not found. Please try again.');
      return;
    }

    setIsSubmitting(true);
    try {
      const result = await client.query<VideoQuery, VideoQueryVariables>({ query: VIDEO, variables: { id: 1 } });

      if (result.data) {
        setVideo(result.data.videos_by_pk);

        history.push('/welcome');
      }
    } catch (err) {
      setIsSubmitting(false);
      console.log(err);
    }
  }
}

export default withOrientationGuard(CodePage);
